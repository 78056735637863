import Axios from 'axios';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useLocation, useNavigate } from 'react-router';
import {
	checkFileType,
	dateConverter,
	defaultDocketTemp,
	getCurrDate,
	parseDentallyFields,
	parseToOptions,
	splitUserId,
} from '../../helpers';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import useOnScreen from '../../hooks/useOnScreen';
import { docketTemp, itemLabelTemp } from '../../templates/templates';
import PackageNewModal from '../../components/Modals/PackageNewModal';
import useMultiFetch from '../../hooks/useMultiFetch';
import FadeOutModal from '../../components/Modals/FadeOutModal';
import CourierNewForm from '../../components/Forms/CourierNewForm';
import FlexModal from '../../components/Modals/FlexModal';
import MultiStepForm from '../../components/MultiStepForm/MultiStepForm';
import { ArrowLeftIcon, CartBuyIcon } from '../../components/SVGIcons/SVGIcons';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { handleCreationError, handleResourceError } from '../../axiosErrorHandling';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import Loading from '../../components/Loading/Loading';
import ProgressBar from '../../components/Progress/ProgressBar';
import Button from '../../components/Button';

const stepRefsDefault = {
	chooseSupplier: { stepNumber: 1, prev: false, next: true, skip: false, submit: false },
	customForm: { stepNumber: 2, prev: true, next: true, skip: false, submit: false },
	accompaniments: { stepNumber: 3, prev: true, next: true, skip: true, submit: false },
	files: { stepNumber: 4, prev: true, next: true, skip: true, submit: false },
	external: { stepNumber: 5, prev: true, next: true, skip: true, submit: false },
	submit: { stepNumber: 6, prev: true, next: false, skip: false, submit: true, saveDraft: true },
	end: { stepNumber: 7, prev: false, next: false, skip: false, submit: false },
};

const CHUNK_SIZE_OLD = 2 * 1024 * 1024;
const CHUNK_SIZE = 100 * 1000;

const PLACEHOLDER_APPOINTMENTS = [
	{
		id: 331289721,
		appointment_cancellation_reason_id: null,
		arrived_at: '2023-04-11T10:34:39.975+01:00',
		booked_via_api: false,
		cancelled_at: null,
		completed_at: '2023-06-20T10:30:00.000+01:00',
		confirmed_at: '2023-04-11T10:34:18.958+01:00',
		created_at: '2023-03-28T13:15:55.213+01:00',
		did_not_attend_at: null,
		duration: 30,
		finish_time: '2023-06-20T10:30:00.000+01:00',
		import_id: null,
		in_surgery_at: '2023-06-20T10:00:00.000+01:00',
		metadata: {},
		notes: null,
		patient_id: 628,
		patient_image_url:
			'https://www.gravatar.com/avatar/e33fe7f87c805ac66a36cd10f2c7944e.jpg?&r=pg&d=identicon&s=190',
		patient_name: 'Alistair Owens',
		payment_plan_id: 89664,
		pending_at: '2023-03-28T13:15:55.213+01:00',
		practitioner_id: 159135,
		reason: 'Continuing treatment',
		room_id: null,
		start_time: '2023-06-20T10:00:00.000+01:00',
		state: 'Completed',
		treatment_description: null,
		updated_at: '2023-06-21T01:25:28.270+01:00',
		user_id: 1741857,
		practitioner_site_id: 'fca1dcf9-f0ea-4974-8740-2d10a9395cfd',
	},
];

export default function OrderCreate() {
	let location = useLocation();
	const changeReqOrderUuid = new URLSearchParams(location.search).get('changeReqOrderUuid');
	const changeReqUuid = new URLSearchParams(location.search).get('changeReqUuid');
	const parentUuid = new URLSearchParams(location.search).get('parentUuid');
	const returnUri = new URLSearchParams(location.search).get('returnUri');
	const name = new URLSearchParams(location.search).get('name');
	const uuid = new URLSearchParams(location.search).get('uuid');

	const { teamState, teamDispatch } = useContext(CurrentTeamContext);
	const { userState } = useContext(CurrentUserContext);

	const preFilledSupplier = { name: name, value: uuid };

	const [formData, setFormData] = useState({
		chosenSupplier: uuid && name ? preFilledSupplier : undefined,
		onBehalfOfBuyerTeam: uuid && name && uuid === teamState.currentTeam.teamUuid ? preFilledSupplier : undefined,
		collect: false,
	});
	const [customFormData, setCustomFormData] = useState({});
	const [customFormFiles, setCustomFormFiles] = useState({});
	const [address, setAddress] = useState({});
	const [stepRefs, setStepRefs] = useState(stepRefsDefault);
	const [dentallyAppointments, setDentallyAppointments] = useState(null);

	const [order, setOrder] = useState({});
	const [courierNewData, setCourierNewData] = useState({});
	const [parentOrder, setParentOrder] = useState({ customFields: {} });
	const [sellerAddresses, setSellerAddresses] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [chosenAddress, setChosenAddress] = useState(null);
	const [hasAddress, setHasAddress] = useState(false);
	const [focused, setFocused] = useState({});
	const [loading, setLoading] = useState(false);
	const [customErrors, setCustomErrors] = useState({});
	const [modalOpen, setModalOpen] = useState({});
	const [dockets, setDockets] = useState([]);
	const [selectedDocket, setSelectedDocket] = useState(0);
	const [isSeller, setIsSeller] = useState(false);
	const [dtlyLoading, setDtlyLoading] = useState(false);
	const [skipped, setSkipped] = useState({});
	const [onBehalfOfAddresses, setOnBehalfOfAddresses] = useState(null);
	const [uploadProgress, setUploadProgress] = useState({});

	const [stepOverwrite, setStepOverwrite] = useState(null);

	const [dentallyIntegration, setDentallyIntegration] = useState(null);
	const [dentallyPatients, setDentallyPatients] = useState(null);

	const [formOpen, setFormOpen] = useState({
		accompaniments: true,
		orderExtFiles: true,
		orderFiles: true,
	});
	const [searchOpen, setSearchOpen] = useState(false);

	const [addSuppAsTrusted, setAddSuppAsTrusted] = useState(null);

	const [orderItems, setOrderItems] = useState({});
	const [orderFiles, setOrderFiles] = useState([]);
	const [orderExtFiles, setOrderExtFiles] = useState({});

	const [packageNewData, setPackageNewData] = useState({ quotedCollectionDateTime: new Date() });

	const docStart = useRef(null);

	const isVisible = useOnScreen(docStart);

	let navigate = useNavigate();

	const { showModal } = useModalsGlobal();

	const { data, setData, fetch, handleCustomOptions, reqLoading, error, setReload } = useMultiFetch([
		{ key: 'packages', url: `${config.apiv1}/package/packages.read/${teamState.currentTeam.teamUuid}` },
		{ key: 'couriers', url: `${config.apiv1}/courier/couriers.read/${teamState.currentTeam.teamUuid}` },
	]);

	function reloadForm() {
		setFormData((data) => ({
			requiredDateTime: Date.now(),
			chosenSupplier: data.chosenSupplier,
			acceptEstimate: data.acceptEstimate,
			acceptPricing: data.acceptPricing,
			acceptPricing: data.acceptPricing,
		}));

		setLoading(true);
		setCustomFormData({});
		setCustomFormFiles({});
		setOrderItems({});
		setOrder({});
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}

	async function addTrusted(teamUuid) {
		if (teamState?.userPermissions?.canAddTrusted) {
			try {
				const res = await Axios({
					url: `${config.apiv1}/team/team.update/trusted.create/${teamState.currentTeam.teamUuid}`,
					method: 'PATCH',
					data: {
						trustedSupplier: {
							teamUuid: teamUuid,
						},
					},
				});
				setTimeout(() => {
					teamDispatch({ type: 'RE-FETCH_TEAM' });
				}, 1000);
			} catch (err) {
				// if (err.response.status === 401) {
				// 	window.location.reload();
				// }
			}
		}
	}

	async function postOrderItems(order) {
		let items = [];
		let reqs = [];

		async function addRequest(url, item) {
			return await Axios({
				url: url,
				method: 'POST',
				data: {
					orderUuid: order?.orderUuid,
					teamUuid: teamState.currentTeam.teamUuid,
					note: item.note,
					packageUuid: item.package?.packageUuid,
					itemDesc: item.itemDesc,
					orderRef: order?.orderRef,
				},
			});
		}

		for (let item in orderItems) {
			reqs.push(addRequest(`${config.apiv1}/order/item.create`, orderItems[item]));
		}
		if (reqs.length > 0) {
			try {
				await Axios.all(reqs).then(
					Axios.spread(async (...responses) => {
						for (let i = 0; i < responses.length; i++) {
							if (responses[i].data) {
								responses[i].data.data.itemLabel = await getFilePath(responses[i]?.data?.data.qrCode);
								responses[i].data.data.saveItemLabel = () =>
									itemLabelTemp(responses[i]?.data?.data, getFilePath, true, { x: 6.5, y: 3.5 });
								items.push(responses[i]?.data?.data);
							}
						}
					})
				);
			} catch (err) {
				return err;
			}

			return setOrderItems(items);
		} else {
			return false;
		}
	}

	function toggleSearch() {
		setSearchOpen(!searchOpen);
	}

	async function appendDentistData(appointments, dentallyToken) {
		async function fetchDentist(practitioner_id) {
			if (practitioner_id) {
				let res = await Axios({
					url: `${config.dentallyAPI}/v1/practitioners/${practitioner_id}`,
					method: 'GET',
					headers: { Authorization: `Bearer ${dentallyToken}` },
				});
				return res?.data;
			}
		}
		for (let i = 0; i < appointments?.length; i++) {
			if (appointments[i]?.practitioner_id) {
				let dentist = await fetchDentist(appointments[i]?.practitioner_id);
				dentist = dentist?.practitioner?.user;
				dentist = `${dentist?.title ? `${dentist?.title} ` : ''}${
					dentist?.first_name ? `${dentist?.first_name} ` : ''
				}${dentist?.last_name ? `${dentist?.last_name}` : ''}`;
				appointments[i].dentist = dentist;
			}
		}
		return appointments;
	}

	async function getDentallyAppointments(id, dentallyToken, beforeDate, afterDate, limit, reasons) {
		try {
			let res = await Axios({
				url: `${config.dentallyAPI}/v1/appointments?patient_id=${id}${
					afterDate ? `&after=${dateConverter(afterDate.toString(), 'YYYY-MM-DD')}` : ''
				}`,
				method: 'GET',
				headers: { Authorization: `Bearer ${dentallyToken}` },
			});
			if (beforeDate) {
				let pastAppointments = await Axios({
					url: `${config.dentallyAPI}/v1/appointments?patient_id=${id}${
						afterDate ? `&before=${dateConverter(beforeDate.toString(), 'YYYY-MM-DD')}` : ''
					}`,
					method: 'GET',
					headers: { Authorization: `Bearer ${dentallyToken}` },
				});
				if (
					res?.data?.appointments &&
					res.data.appointments?.length !== 0 &&
					pastAppointments.data.appointments &&
					pastAppointments.data.appointments?.length !== 0
				) {
					res.data.appointments = [...res.data.appointments, ...pastAppointments.data.appointments];
				} else if (res?.data?.appointments && res.data.appointments?.length !== 0) {
					res.data.appointments = [...res.data.appointments];
				} else if (pastAppointments?.data?.appointments && pastAppointments.data.appointments?.length !== 0) {
					res.data.appointments = [...pastAppointments.data.appointments];
				}
			}
			if (res?.data?.appointments && res.data.appointments?.length !== 0) {
				// if (res.data.appointments?.length === 0 && process.env.REACT_APP_ENV !== 'production') {
				// 	res.data.appointments = PLACEHOLDER_APPOINTMENTS;
				// }
				if (limit) {
					let newAppointmentsLength = [];
					for (let i = 0; i < limit; i++) {
						newAppointmentsLength.push(res.data.appointments[i]);
					}
					res.data.appointments = newAppointmentsLength.filter((appointment) =>
						reasons.includes(appointment.reason)
					);
				}

				await appendDentistData(res?.data?.appointments, dentallyToken);

				setDentallyAppointments(res?.data?.appointments);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function parseAppointmentFilters(type) {
		switch (Number(type)) {
			// Only next appointment
			case 1:
				return { before: null, after: new Date(), limit: 1 };
			// All future appointments
			case 2:
				return { before: null, after: new Date() };
			// All future and past appointments
			case 3:
				return { before: new Date(), after: new Date() };
			default:
				return { before: null, after: new Date(), limit: 1 };
		}
	}

	async function postCourierNew() {
		setUpdateLoading(true);
		function checkInvalidFields() {
			let form = document.getElementById('courierForm');
			const elements = form.querySelectorAll('[isrequired]');
			for (let i = 0; i < elements.length; i++) {
				if (!courierNewData[elements[i].getAttribute('isrequired')]) {
					setCustomErrors({
						...customErrors,
						[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
					});
					elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
					return true;
				}
			}
		}

		let invalid = checkInvalidFields();

		if (invalid) {
			setUpdateLoading(false);
			return;
		}

		const data = {
			accountNumber: courierNewData?.accountNmbr,
			teamUuid: teamState.currentTeam.teamUuid,
			telephone: courierNewData?.telephone,
			companyName: courierNewData?.name,
			notes: courierNewData?.notes,
			email: courierNewData?.email,
			address: courierNewData?.address,
		};

		try {
			const res = await Axios({
				url: `${config.apiv1}/courier/courier.create`,
				method: 'POST',
				data: data,
			});

			if (res.data?.ok) {
				setTimeout(() => {
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'New courier created successfully',
					});
					setUpdateLoading(false);
					handleModal('courierNewModal', false);
					setReload(true);
					setCourierNewData({});
				}, 500);
			}
		} catch (err) {
			// if (err.response.status === 401) {
			// 	window.location.reload();
			// }
		}
	}

	async function fetchParentOrder(parentUuid, setLoad) {
		try {
			let res = await Axios({
				url: `${config.apiv1}/order/order.read/${parentUuid}`,
				method: 'GET',
			});
			if (res?.data?.ok && res?.data?.data) {
				setParentOrder(res.data.data);
				if (setLoad) {
					setLoaded(true);
				}
			}
		} catch (err) {
			if (setLoad) {
				setLoaded(true);
			}
			return err;
		}
	}

	async function fetchTrustedSuppliers(setLoad) {
		try {
			const response = await Axios({
				url: `${config.apiv1}/team/team.read/trusted/${teamState.currentTeam.teamUuid}`,
				method: 'GET',
			});
			const checkIsDuplicate = (arr, arrItem) => {
				let isDuplicate = false;
				for (let i = 0; i < arr.length; i++) {
					if (arr[i].value === arrItem.value) {
						isDuplicate = true;
					}
				}
				return isDuplicate;
			};
			if (response?.data?.data?.length > 0) {
				let supplierArr = response?.data?.data;
				supplierArr = parseToOptions(supplierArr, 'suppliers');
				if (name && uuid && !checkIsDuplicate(supplierArr, preFilledSupplier)) {
					supplierArr.push(preFilledSupplier);
				}
				setSuppliers(supplierArr);
			} else if (name && uuid) {
				setSuppliers([preFilledSupplier]);
			}
			if (setLoad) {
				setLoaded(true);
			}
		} catch (err) {
			if (setLoad) {
				setLoaded(true);
			}
			return err;
		}
	}

	function handleSkipped(step) {
		setSkipped(step);
	}

	function handleModal(name, value) {
		setModalOpen((modalOpen) => ({
			...modalOpen,
			[name]: value,
		}));
	}

	const handlePatientData = (patient) => {
		let hintFormat = dentallyIntegration?.settings?.hintFormat;
		if (hintFormat) {
			for (let key in patient) {
				if (hintFormat.includes(key)) {
					if (
						patient[key] !== undefined &&
						patient[key] !== null &&
						patient[key] !== 'undefined' &&
						patient[key] !== 'null'
					) {
						hintFormat = hintFormat?.replaceAll(`{{${key}}}`, patient[key]);
					} else {
						hintFormat = hintFormat?.replaceAll(`{{${key}}}`, '');
					}
				}
			}
		}
		let parsedPatient = { id: patient?.id };
		for (let field in dentallyIntegration?.settings) {
			if (dentallyIntegration?.settings[field] === true && field !== 'hintFormat') {
				parsedPatient[field] = patient[field];
			}
		}

		setDentallyPatients(null);
		setFormData((formData) => ({
			...formData,
			patient: parsedPatient,
			buyerHint: hintFormat,
		}));
		setData((data) => ({
			...data,
			dentally: null,
		}));
	};

	const handleFormData = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	const handleNestedFormData = (data, objKey) => {
		const { name, value } = data || {};
		setCustomErrors({});
		let newData = { [name]: value };
		if (formData[objKey] && data.name !== 'service') {
			newData = { ...formData[objKey], [name]: value };
		}
		setFormData((formData) => ({
			...formData,
			[objKey]: newData,
		}));
	};

	const handleCustomFormData = (value, name) => {
		setCustomErrors({});
		setCustomFormData((customFormData) => ({
			...customFormData,
			[name]: value,
		}));
	};

	const handleCustomFormSelectData = (data, fieldName) => {
		setCustomErrors({});
		setCustomFormData((customFormData) => ({
			...customFormData,
			[fieldName]: data.value ? data.value : data,
		}));
	};

	const handleFormSelectData = (data, fieldName) => {
		function checkIfNoTrusted() {
			let trustedArr = teamState?.currentTeam?.trustedSuppliers?.map((supplier) => supplier.teamUuid);

			if (
				fieldName === 'chosenSupplier' &&
				teamState?.userPermissions?.canAddTrusted &&
				!trustedArr?.includes(data?.value)
			) {
				setAddSuppAsTrusted(() => data);
			}
		}

		async function fetchOnBehalfOfAddresses(data) {
			let buyerTeam = await Axios({
				method: 'GET',
				url: `${config.apiv1}/team/team.read/${data.value}`,
			});
			setOnBehalfOfAddresses(() => buyerTeam?.data?.data?.teamInfo?.addresses);
		}

		if (fieldName === 'chosenSupplier' && teamState.currentTeam.teamUuid === data?.value) {
			fetchOnBehalfOfAddresses(data);
			setFormData((formData) => ({
				...formData,
				onBehalfOfBuyerTeam: { value: teamState.currentTeam.teamUuid },
			}));
		}

		setCustomErrors({});
		checkIfNoTrusted();

		if (fieldName === 'onBehalfOfBuyerTeam') {
			fetchOnBehalfOfAddresses(data);
		}

		setFormData((formData) => ({
			...formData,
			[fieldName]: data,
		}));

		return true;
	};

	const handleAddressInput = (value, name) => {
		setCustomErrors({});
		setAddress((address) => ({
			...address,
			[name]: value,
		}));
	};

	const handleDocketSelect = (props) => {
		let index = dockets.findIndex((docket) => docket.docketUuid === props.value);
		handleInitialFieldFormat(dockets[index]?.fields);
		setSelectedDocket(index);
	};

	const handleFocused = (e) => {
		const { name } = e.target;
		setFocused((focused) => ({
			...focused,
			[name]: !focused[name],
		}));
	};

	function handleInitialFieldFormat(docketFields) {
		const typesFilter = ['header', 'drawingTool', 'graphic'];
		let initData = {};
		docketFields.forEach((field) => {
			if (field?.type === 'paragraph' && field?.data?.include === true) {
				initData[`included_field_${uuidv4()}`] = field.data.paragraph;
			} else if (!typesFilter.includes(field?.type)) {
				initData[field?.data?.fieldName] = undefined;
			}
		});
		setCustomFormData(initData);
	}

	async function handleCustomFiles(value, name) {
		name = name.replace(' ', '').toLowerCase();
		setCustomFormFiles({
			...customFormFiles,
			[name]: value ? { file: value, meta: { fieldName: name, originalname: value?.name } } : null,
		});
	}

	const handleCourierNewData = (value, name) => {
		setCustomErrors({});
		setCourierNewData((courierNewData) => ({
			...courierNewData,
			[name]: value,
		}));
	};

	const handleCourierAddressInput = (value, name) => {
		setCustomErrors({});
		setCourierNewData((courierNewData) => ({
			...courierNewData,
			['address']: { ...courierNewData.address, [name]: value },
		}));
	};

	const handlePackages = async (packages, newPackageUuid) => {
		await fetch('packages');
		let newPackage = packages[newPackageUuid];
		handleFormData(
			{
				value: { packageUuid: newPackage?.packageUuid },
				name: newPackage?.packageRef,
			},
			'package'
		);
	};

	const handleNewFiles = (newFiles, name) => {
		if (!newFiles?.length) {
			newFiles = [newFiles];
		}
		for (let i = 0; i < newFiles?.length; i++) {
			newFiles[i].fileUuid = uuidv4();
		}
		setOrderFiles(() => [...orderFiles, ...newFiles]);
	};

	const removeFile = (fileUuid) => {
		let newFiles = orderFiles.filter((file) => file.fileUuid !== fileUuid);
		setOrderFiles(() => [...newFiles]);
	};

	async function getDentallyPatients(searchValue, dentallyToken) {
		try {
			let res = await Axios({
				url: `${config.dentallyAPI}/v1/patients?query=${searchValue}`,
				method: 'GET',
				headers: { Authorization: `Bearer ${dentallyToken}` },
			});
			if (res?.data?.patients) {
				setDentallyPatients(res?.data?.patients);
			}
		} catch (err) {
			handleModal('dentallyTokenModal', {
				open: true,
				header: 'Dentally needs updating. Please ask your Team admin to look at Dentally in their Apps Admin page',
			});
		}
	}

	async function formatIntegrationMaps(docketUuid) {
		let appFormat = {
			labmanager: {},
		};
		try {
			let seller = await Axios.get(`${config.apiv1}/team/team.read/${formData?.chosenSupplier?.value}`);

			if (!seller || !seller?.data?.data?.integrations?.labmanager) {
				return false;
			}
			seller = seller?.data?.data;

			let res = await Axios.get(
				`${config.apiv1}/integration/integration.read/${seller?.integrations?.labmanager}`
			);

			let integration = res?.data?.data;

			if (integration && selectedDocket !== null && docketUuid !== null && integration?.settings?.dockets) {
				appFormat.labmanager = integration?.settings?.dockets[docketUuid];
				return appFormat;
			}
		} catch (err) {
			console.log(err);
		}
	}

	function checkIfRequiredByField() {
		let fieldName = null;
		for (let i = 0; i < dockets[selectedDocket]?.fields?.length; i++) {
			if (dockets[selectedDocket]?.fields[i]?.data?.requiredBy) {
				fieldName = dockets[selectedDocket]?.fields[i]?.data?.fieldName;
			}
		}
		return fieldName;
	}

	const updateDebounceSearch = useCallback(
		debounce(({ searchValue, dentallyToken }, type) => {
			if (type === 'dentallyPatients') {
				getDentallyPatients(searchValue, dentallyToken);
			}
			if (type === 'suppliers') {
				handleCustomOptions([
					{
						key: 'searchSuppliers',
						url: `${config.apiv1}/team/team.read?search=${searchValue}&isPublic=true`,
					},
				]);
			}
		}),
		[]
	);

	function debounce(cb, delay = 300) {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	}

	function handleCourierSelectData(data, fieldName) {
		setCustomErrors({});
		setCourierNewData((courierNewData) => ({
			...courierNewData,
			[fieldName]: data.value,
		}));
	}

	function handleFadeOutModal(state) {
		const second = 1000;
		setModalOpen((modalOpen) => ({
			...modalOpen,
			['modalFading']: state,
		}));
		setTimeout(() => {
			setModalOpen((modalOpen) => ({
				...modalOpen,
				['modalFading']: { show: true, isFading: true, message: state.message },
			}));
		}, second * 3);
	}

	function getFilePath(file) {
		let res = Axios({
			url: `${config.api}/file?path=${file.path}`,
			method: 'GET',
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(response.data);
			return url;
		});
		return res;
	}

	async function postOrderItem(orderItem) {
		const data = {
			...orderItem,
			orderUuid: order?.orderUuid,
			orderRef: order?.orderRef,
			teamUuid: teamState.currentTeam.teamUuid,
		};

		try {
			const res = await Axios({
				url: `${config.apiv1}/order/item.create`,
				method: 'POST',
				data: data,
			});
			if (res.data?.ok) {
				res.data.data.itemLabel = await getFilePath(res.data.data.qrCode);
				res.data.data.saveItemLabel = () => itemLabelTemp(res.data.data, getFilePath, true, { x: 6.5, y: 3.5 });
				setOrder(() => ({
					...order,
					['items']: [...order.items, res.data.data],
				}));
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function createImgUrls(files) {
		if (files.length > 0) {
			let imgs = [];
			for (let i = 0; i < files.length; i++) {
				if (checkFileType(files[i]?.filename?.split('.')[1]) === 1 && files[i].fileAttachmentType === 2) {
					try {
						const res = await Axios({
							url: `${config.api}/file?path=${files[i].path}`,
							method: 'GET',
							responseType: 'blob',
						});

						if (res.data) {
							const url = window.URL.createObjectURL(new Blob([res.data]));
							let file = files[i];
							file.url = url;
							imgs.push(file);
						}
					} catch (err) {
						// if (err.response.status === 401) {
						// 	window.location.reload();
						// }
					}
				}
			}
			return imgs;
		}
	}

	const uploadChunk = async (chunk, index, filename, chunksDirUuid) => {
		const formData = new FormData();
		formData.append('teamUuid', teamState.currentTeam.teamUuid);
		formData.append('fileAttachmentType', 1);
		formData.append('chunkIndex', index);
		formData.append('filename', filename);
		formData.append('chunksDirUuid', chunksDirUuid);
		formData.append('chunk', chunk);

		await Axios.post(`${config.apiv1}/file/chunk.create`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};

	async function postFiles(newFiles, orderUuid, ignoreHook = false, noProgress = false) {
		const filesTotalSize = newFiles.reduce((accumulator, currentValue) => accumulator + currentValue?.size, 0);
		const totalChunksAllFiles = Math.ceil(filesTotalSize / CHUNK_SIZE);
		let uploadedChunksTotal = 0;

		for (let i = 0; i < newFiles?.length; i++) {
			if (!newFiles[i]) return;

			const chunksDirUuid = uuidv4();
			const file = newFiles[i];
			const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
			const filename = file.name?.split('.')[0];
			const mimetype = file.name?.split('.')[1];

			for (let i = 0; i < totalChunks; i++) {
				const start = i * CHUNK_SIZE;
				const end = Math.min(start + CHUNK_SIZE, file.size);
				const chunk = file.slice(start, end);

				await uploadChunk(chunk, i, filename, chunksDirUuid);
				uploadedChunksTotal += 1;
				let progressPercent = Math.round((uploadedChunksTotal / totalChunksAllFiles) * 100);
				if (filesTotalSize >= CHUNK_SIZE && noProgress === false) {
					setUploadProgress((files) => ({
						...files,
						order: {
							percent: progressPercent <= 100 ? progressPercent : 100,
							complete: progressPercent >= 100,
						},
					}));
				}
			}
			let res = await Axios.post(`${config.apiv1}/file/chunks.merge`, {
				filename,
				totalChunks,
				chunksDirUuid,
				mimetype,
				orderUuid,
				ignoreHook,
				fileAttachmentType: 1,
				teamUuid: teamState.currentTeam.teamUuid,
				dupeTeamUuid: formData?.chosenSupplier?.value,
				originalname: file.name,
				size: file.size,
			});
		}
		return;
	}

	async function postExtFiles(order) {
		let extFiles = [];
		let reqs = [];

		async function addRequest(url, extFile) {
			return await Axios({
				url: url,
				method: 'POST',
				data: {
					teamUuid: teamState?.currentTeam?.teamUuid,
					serviceUuid: extFile.service?.serviceUuid,
					userUuid: splitUserId(userState.currUser.sub),
					orderUuid: order?.orderUuid,
					fields: {
						folderPathName: extFile.folderPathName || undefined,
						recipientEmail: extFile.recipientEmail || undefined,
						senderEmail: extFile.senderEmail || undefined,
						accountName: extFile.accountName || undefined,
						scanDate: extFile.scanDate || undefined,
						fileName: extFile.fileName || undefined,
						subject: extFile.subject || undefined,
						date: extFile.date || undefined,
						note: extFile.note || undefined,
					},
				},
			});
		}

		for (let extFile in orderExtFiles) {
			reqs.push(
				addRequest(`${config.apiv1}/externalAttachment/externalAttachment.create`, orderExtFiles[extFile])
			);
		}
		if (reqs.length > 0) {
			try {
				await Axios.all(reqs).then(
					Axios.spread(async (...responses) => {
						for (let i = 0; i < responses.length; i++) {
							extFiles.push(responses[i]?.data?.data);
						}
					})
				);
			} catch (err) {
				return err;
			}

			return setOrderExtFiles(extFiles);
		} else {
			return false;
		}
	}

	async function generateAndPostDocket(
		order,
		customFormFiles,
		customFormData,
		isChangeReq,
		orderExtFiles,
		ignoreHook
	) {
		let changeReqPDFs = order.files.filter(
			(file) => file.originalname.includes('change_request') && file.fileAttachmentType === 1
		);
		let orderFiles = order.files;
		if (order.files) {
			orderFiles = await createImgUrls(orderFiles);
		}
		let customDataFields = { ...customFormData };
		if (
			order?.integrations?.soe?.dentally?.appData &&
			typeof order?.integrations?.soe?.dentally?.appData === 'object'
		) {
			let filter = ['appointments'];
			customDataFields = { ...customDataFields, ...order.integrations.soe.dentally.appData };
			for (let i = 0; i < filter.length; i++) {
				if (customDataFields && filter[i] && customDataFields[filter[i]]) {
					delete customDataFields[filter[i]];
				}
			}
		}
		let docketPDF = await docketTemp(
			Object.keys(customDataFields)?.length !== 0 ? customDataFields : {},
			Object.keys(customFormFiles)?.length !== 0 ? Object.values(customFormFiles) : {},
			teamState.currentTeam.teamInfo.teamName,
			order,
			orderFiles,
			teamState.currentTeam,
			order?.shippingAddress,
			order?.behalfOf?.name,
			parseDocketFields(dockets[selectedDocket]),
			isChangeReq,
			orderExtFiles
		);
		isChangeReq
			? (docketPDF.name = `change_request_${changeReqPDFs?.length + 1}_${getCurrDate()}.pdf`)
			: (docketPDF.name = `docket_${getCurrDate()}.pdf`);

		if (ignoreHook) {
			fileData.append('ignoreHook', true);
		}

		let res = await postFiles([docketPDF], order?.orderUuid, ignoreHook, true);
		return res;
	}

	function parseDocketFields(docket) {
		let parsedFields = {};
		for (let i = 0; i < docket?.fields?.length; i++) {
			if (docket?.fields[i]?.data?.fieldName) {
				parsedFields[docket?.fields[i]?.data?.fieldName] = {
					fieldText: docket?.fields[i]?.data?.fieldText,
				};
			}
		}
		return parsedFields;
	}

	function checkInvalidFields(formId) {
		let invalid = false;
		if (formId) {
			let elements = document.querySelectorAll('[isrequired]');
			let form = document.getElementById(formId);
			if (!form) {
				return invalid;
			}
			elements = form.querySelectorAll('[isrequired]');
			for (let i = 0; i < elements.length; i++) {
				if (!formData[elements[i].getAttribute('isrequired')]) {
					setCustomErrors({
						...customErrors,
						[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
					});
					elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
					return (invalid = true);
				}
			}
		}
		return invalid;
	}

	async function postOrder(status) {
		try {
			let invalid = checkInvalidFields(stepRefs?.submit?.stepNumber);

			if (invalid) {
				return;
			}

			if (orderFiles?.length !== 0) {
				const filesTotalSize = orderFiles.reduce(
					(accumulator, currentValue) => accumulator + currentValue?.size,
					0
				);

				if (filesTotalSize >= CHUNK_SIZE) {
					setUploadProgress((files) => ({
						...files,
						order: {
							percent: 0,
							complete: false,
						},
					}));
				}
			}

			setLoading(true);

			let orderStatus = 1;

			if (status === 'draft') {
				orderStatus = 0;
			}

			const orderData = new FormData();

			if (!changeReqUuid) {
				orderData.append(
					'authorization',
					teamState?.userPermissions['authorizedOnBehalf'] === 'true' ||
						teamState?.userPermissions['authorizedOnBehalf'] === true
						? true
						: formData.authorization
				);
				orderData.append(
					'behalfOf',
					formData.behalfOf && formData.behalfOf.value !== false
						? JSON.stringify({ name: formData.behalfOf.name, userUuid: formData.behalfOf.value })
						: JSON.stringify({
								name: userState.currUser.name,
								userUuid: splitUserId(userState.currUser.sub),
						  })
				);
				if (formData.patient) {
					let parsedFields = parseDentallyFields(
						formData?.patient,
						dentallyIntegration?.data?.data?.settings
					);
					if (dentallyAppointments) {
						parsedFields.appointments = dentallyAppointments;
					}
					orderData.append('dentally', JSON.stringify(parsedFields));
				}
				orderData.append('collect', formData.collect);
				orderData.append('acceptEstimate', formData.acceptEstimate);
				orderData.append('acceptPricing', formData.acceptPricing);
				orderData.append('orderProcessingStatus', orderStatus);
				if (formData.buyerHint) {
					orderData.append('buyerHint', formData.buyerHint);
				}
				orderData.append('orderType', 1);
				if (parentUuid) {
					orderData.append('parentUuid', parentUuid);
				}
				if (!chosenAddress) {
					orderData.append(
						'shippingAddress',
						JSON.stringify({
							...address,
						})
					);
				} else if (formData.onBehalfOfBuyerTeam) {
					let chosenAddrObj = onBehalfOfAddresses?.filter(
						(address) => address?.addressUuid === chosenAddress
					)[0];
					if (chosenAddrObj) {
						orderData.append(
							'shippingAddress',
							JSON.stringify({
								...chosenAddrObj,
							})
						);
					}
				} else {
					let chosenAddrObj =
						teamState?.currentTeam?.teamInfo?.addresses &&
						teamState?.currentTeam?.teamInfo?.addresses.filter(
							(address) => address?.addressUuid === chosenAddress
						)[0];
					if (chosenAddrObj) {
						orderData.append(
							'shippingAddress',
							JSON.stringify({
								...chosenAddrObj,
							})
						);
					}
				}
			}

			orderData.append('buyerUserUuid', splitUserId(userState.currUser.sub));
			orderData.append('buyerTeamUuid', formData.onBehalfOfBuyerTeam?.value || teamState.currentTeam.teamUuid);

			if (!uuid) {
				orderData.append('sellerTeamUuid', formData.chosenSupplier?.value);
			} else {
				orderData.append('sellerTeamUuid', uuid);
			}

			if (Object.keys(customFormData).length !== 0) {
				// ! Remake this format, "customFormData" should be a nested object, it should not spread the fields
				let requiredBy = await checkIfRequiredByField();

				if (requiredBy && customFormData[requiredBy]) {
					orderData.append('requiredDateTime', customFormData[requiredBy]);
				}
				let included_fields = { ...customFormData.included_fields };

				delete customFormData.included_fields;

				let customData = {
					docketName: dockets[selectedDocket]?.name,
					docketUuid: dockets[selectedDocket]?.docketUuid,
					...customFormData,
					...included_fields,
				};

				let integrationMaps = await formatIntegrationMaps(dockets[selectedDocket]?.docketUuid);

				if (integrationMaps) {
					orderData.append('integrationMaps', JSON.stringify(integrationMaps));
				}
				orderData.append('customFields', JSON.stringify(customData));
			}

			if (!changeReqUuid) {
				Axios({
					url: `${config.apiv1}/order/order.create`,
					method: 'POST',
					data: orderData,
				})
					.then(async (res) => {
						if (res.data?.ok) {
							const order = res?.data?.data;
							setOrder(order);
							await generateAndPostDocket(
								order,
								customFormFiles,
								customFormData,
								false,
								orderExtFiles,
								orderStatus === 0
							);
							if (!skipped?.external) {
								await postExtFiles(order);
							}
							if (!skipped?.accompaniments) {
								await postOrderItems(order);
							}
							if (orderFiles.length !== 0) {
								await postFiles([...orderFiles], order.orderUuid, orderStatus === 0);
							}
							if (Object.keys(customFormFiles)?.length !== 0) {
								let formattedFiles = Object.values(customFormFiles).map((file, key) => {
									return file?.file;
								});
								await postFiles([...formattedFiles], order.orderUuid, orderStatus === 0);
							}
							handleFadeOutModal({
								show: true,
								isFading: false,
								message: 'New order created successfully',
							});

							setLoading(false);

							if (formData.newTrusted) {
								addTrusted(addSuppAsTrusted?.value);
							}
						} else {
							handleFadeOutModal({
								show: true,
								isFading: false,
								message: 'Error when creating order',
							});
							setLoading(false);
						}
					})
					.catch((err) => {
						console.log(err);
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'Error when creating order',
						});
						setLoading(false);
						handleCreationError(err, navigate, showModal);
					});
			} else {
				orderData.append('isChangeRequest', true);
				Axios({
					url: `${config.apiv1}/order/order.update/${changeReqOrderUuid}`,
					method: 'PATCH',
					data: orderData,
				})
					.then(async (res) => {
						if (res.data?.ok) {
							const order = res?.data?.updatedOrder;
							setOrder(order);
							await generateAndPostDocket(order, customFormFiles, customFormData, true, orderExtFiles);
							if (!skipped?.external) {
								await postExtFiles(order);
							}
							if (!skipped?.accompaniments) {
								await postOrderItems(order);
							}
							if (orderFiles.length !== 0) {
								await postFiles([...orderFiles, ...Object.values(customFormFiles)], order.orderUuid);
							}
							handleFadeOutModal({
								show: true,
								isFading: false,
								message: 'Order updated successfully',
							});
							setLoading(false);
						} else {
							handleFadeOutModal({
								show: true,
								isFading: false,
								message: 'Error when updating order',
							});
							setLoading(false);
						}
					})
					.catch((err) => {
						console.log(err);
						handleFadeOutModal({
							show: true,
							isFading: false,
							message: 'Error when updating order',
						});
						setLoading(false);
						handleCreationError(err, navigate, showModal);
					});
			}
		} catch (err) {
			console.log(err);
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'Error when managing order',
			});
			setLoading(false);
			setStepOverwrite(changeReqUuid ? 5 : 6);
			handleCreationError(err, navigate, showModal);
		}
	}

	function getSupplierPreferences(teamUuid) {
		for (let i = 0; i < teamState?.currentTeam?.trustedSuppliers?.length; i++) {
			if (teamState.currentTeam.trustedSuppliers[i].teamUuid === teamUuid) {
				return teamState.currentTeam.trustedSuppliers[i].preferences || {};
			}
		}
	}

	async function getDockets(supplierUuid) {
		function handleStepOrder(stepOrder) {
			switch (stepOrder) {
				case 'default':
					setStepRefs(stepRefsDefault);
					break;
				case 'changeRequest':
					setStepRefs({
						customForm: { stepNumber: 1, prev: true, next: true, skip: false, submit: false },
						accompaniments: { stepNumber: 2, prev: true, next: true, skip: true, submit: false },
						files: { stepNumber: 3, prev: true, next: true, skip: true, submit: false },
						external: { stepNumber: 4, prev: true, next: true, skip: true, submit: false },
						submit: { stepNumber: 5, prev: true, next: false, skip: false, submit: true, saveDraft: false },
						end: { stepNumber: 6, prev: false, next: false, skip: false, submit: false },
					});
					break;
				default:
					setStepRefs(stepRefsDefault);
			}
		}

		const res = await Axios({
			url: `${config.apiv1}/docket/dockets.read/${supplierUuid}`,
			method: 'GET',
		});

		if (res?.data?.ok) {
			if (res.data.data) {
				let ORDER_FORM = 1;
				let CHANGE_REQ = 2;

				res.data.data = res?.data?.data.filter((docket) => {
					if (docket.published) {
						if (!changeReqUuid && docket.docketType === CHANGE_REQ) {
							return false;
						}
						return true;
					}
				});

				handleStepOrder('default');

				if (res?.data?.data?.length < 1) {
					res.data.data = [defaultDocketTemp(teamState.currentTeam)];
				}

				let defaultDock = res.data?.data.filter(
					(docket) => docket.default === true && docket.docketType === ORDER_FORM
				);

				let index = 0;

				if (changeReqUuid && uuid) {
					let filteredDockets = res?.data?.data.filter((docket) => docket.docketType === CHANGE_REQ);
					index = filteredDockets.findIndex((docket) => docket.docketUuid === changeReqUuid);
					setSelectedDocket(index);
					setDockets(filteredDockets);
					handleStepOrder('changeRequest');
					handleInitialFieldFormat(filteredDockets[index]?.fields);
				} else if (defaultDock?.length === 0) {
					let filteredDockets = res?.data?.data?.filter((docket) => docket.docketType === ORDER_FORM);
					setSelectedDocket(0);
					setDockets(filteredDockets);
					handleInitialFieldFormat(filteredDockets[0]?.fields);
				} else {
					let filteredDockets = res?.data?.data?.filter((docket) => docket.docketType === ORDER_FORM);
					index = filteredDockets.findIndex((docket) => docket.docketUuid === defaultDock[0].docketUuid);
					setSelectedDocket(index || 0);
					setDockets(() => filteredDockets);
					handleInitialFieldFormat(filteredDockets[index]?.fields);
				}
			} else {
				return setDockets([defaultDocketTemp(teamState.currentTeam)]);
			}
		} else {
			return setDockets([defaultDocketTemp(teamState.currentTeam)]);
		}
	}

	async function getSellerAddresses(sellerTeamUuid) {
		let addresses = await Axios({
			url: `${config.apiv1}/team/team.read/${sellerTeamUuid}`,
			method: 'GET',
		});
		if (addresses?.data?.data) {
			addresses = [...addresses?.data?.data?.teamInfo?.addresses, addresses?.data?.data?.registeredAddress];
		}
		setSellerAddresses(addresses);
	}

	function addItem() {
		let invalid = checkInvalidFields('accompanimentsAdd');

		if (invalid) {
			return false;
		}

		let tempUuid = uuidv4();
		setOrderItems((items) => ({
			...items,
			[tempUuid]: {
				itemUuid: tempUuid,
				itemDesc: formData?.itemDesc || '',
				note: formData?.note || '',
				package: data?.packages[formData.package?.value?.packageUuid],
			},
		}));

		setFormData(() => ({
			...formData,
			itemDesc: null,
			note: null,
		}));

		return true;
	}

	function addFile() {
		let invalid = checkInvalidFields('filesAdd');

		if (invalid) {
			return;
		}

		let tempUuid = uuidv4();
		setOrderFiles((files) => ({
			...files,
			[tempUuid]: {
				fileUuid: tempUuid,
				displayName: file?.displayName,
				caption: file?.caption,
				file: file?.file,
			},
		}));

		setFormData(() => ({
			...formData,
			file: null,
		}));
	}

	function addExtFile() {
		let invalid = checkInvalidFields('externalAdd');

		if (invalid) {
			return;
		}

		let tempUuid = uuidv4();
		setOrderExtFiles((files) => ({
			...files,
			[tempUuid]: {
				extFileUuid: tempUuid,
				service: formData?.extFile?.service,
				folderPathName: formData?.extFile?.folderPathName || undefined,
				recipientEmail: formData?.extFile?.recipientEmail || undefined,
				senderEmail: formData?.extFile?.senderEmail || undefined,
				accountName: formData?.extFile?.accountName || undefined,
				scanDate: formData?.extFile?.scanDate || undefined,
				fileName: formData?.extFile?.fileName || undefined,
				subject: formData?.extFile?.subject || undefined,
				date: formData?.extFile?.date || undefined,
				note: formData?.extFile?.note || undefined,
			},
		}));

		setFormData(() => ({
			...formData,
			extFile: null,
		}));
	}

	function deleteItem(orderItemUuid) {
		if (orderItemUuid) {
			let newOrderItems = { ...orderItems };
			delete newOrderItems[orderItemUuid];
			if (Object.keys(newOrderItems)?.length === 0) {
				setFormOpen((formOpen) => ({
					...formOpen,
					accompaniments: true,
				}));
			}
			setOrderItems(newOrderItems);
		}
	}

	function deleteFile(fileUuid) {
		if (fileUuid) {
			let newOrderFiles = { ...orderFiles };
			delete newOrderFiles[fileUuid];
			if (Object.keys(newOrderFiles)?.length === 0) {
				setFormOpen((formOpen) => ({
					...formOpen,
					orderFiles: true,
				}));
			}
			setOrderFiles(newOrderFiles);
		}
	}

	function deleteExtFile(extFileUuid) {
		if (extFileUuid) {
			let newOrderExtFiles = { ...orderExtFiles };
			delete newOrderExtFiles[extFileUuid];
			setOrderExtFiles(newOrderExtFiles);
		}
	}

	async function getDentallyIntegration(integrationUuid) {
		try {
			let res = await Axios({
				method: 'GET',
				url: `${config.apiv1}/integration/integration.read/${integrationUuid}`,
			});
			if (res.data && res.data.data) {
				setDentallyIntegration(res.data.data);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getAndSetSupplier(teamUuid) {
		let supplier = await Axios({
			url: `${config.apiv1}/team/team.read/${teamUuid}`,
			method: 'GET',
		});

		if (supplier?.data?.data) {
			setFormData((formData) => ({
				...formData,
				supplier: supplier?.data?.data,
			}));
		}
	}

	useEffect(() => {
		if (teamState) {
			const addresses = teamState?.currentTeam?.teamInfo?.addresses;
			if (addresses) {
				function getAddressFromList() {
					for (let i = 0; i < addresses.length; i++) {
						if (addresses[i] && addresses[i]?.addressLine1) {
							return setChosenAddress(addresses[i]?.addressUuid);
						}
					}
				}
				// Check if first entry is null or undefined
				if (addresses?.length > 0) {
					setHasAddress(true);
					let foundDefault = false;
					for (let i = 0; i < addresses.length; i++) {
						if (addresses[i]?.isDefault && addresses[i]?.addressLine1) {
							foundDefault = true;
							setChosenAddress(addresses[i]?.addressUuid);
						}
					}
					if (!foundDefault) {
						getAddressFromList();
					}
				}
			}
			if (formData.chosenSupplier) {
				const prefs = getSupplierPreferences(formData.chosenSupplier.value);
				if (prefs) {
					prefs?.acceptEstimate && handleFormData(prefs?.acceptEstimate, 'acceptEstimate');
					prefs?.acceptPricing && handleFormData(prefs?.acceptPricing, 'acceptPricing');
				}

				getAndSetSupplier(formData.chosenSupplier.value);
			}

			if (teamState.currentTeam?.integrations?.dentally) {
				getDentallyIntegration(teamState.currentTeam?.integrations?.dentally);
			}

			getDockets(formData?.chosenSupplier?.value || uuid);
			getSellerAddresses(formData?.chosenSupplier?.value || uuid);
		}
	}, [formData.chosenSupplier]); // eslint-disable-next-line

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (parentUuid) {
				fetchTrustedSuppliers();
				fetchParentOrder(parentUuid, true);
			} else {
				fetchTrustedSuppliers(true);
			}
		}
		return () => (mounted = false);
	}, []);

	useEffect(() => {
		let mounted = true;
		if (mounted && dentallyIntegration && formData?.patient) {
			let filters = parseAppointmentFilters(dentallyIntegration?.settings?.appointmentFilterType);
			getDentallyAppointments(
				formData.patient?.id,
				dentallyIntegration?.token,
				filters?.before,
				filters?.after,
				filters?.limit,
				dentallyIntegration?.settings?.reasons ? Object.keys(dentallyIntegration?.settings?.reasons) : null
			);
		}
		return () => (mounted = false);
	}, [dentallyIntegration, formData.patient]);

	return (
		<>
			<div ref={docStart}></div>
			{loaded && !updateLoading && (
				<>
					<FadeOutModal
						show={modalOpen['modalFading']?.show}
						isFading={modalOpen['modalFading']?.isFading}
						message={modalOpen['modalFading']?.message}
					/>
					{modalOpen['packageNewModal'] && (
						<>
							<PackageNewModal
								setShow={handleModal}
								formData={packageNewData}
								setFormData={setPackageNewData}
								couriers={data?.couriers?.data?.data}
								sellerAddresses={sellerAddresses}
								packages={data?.packages}
								setCustomErrors={setCustomErrors}
								customErrors={customErrors}
								missingOptions={{
									message: 'Add courier',
									callback: () => {
										handleModal('courierNewModal', true);
									},
								}}
								address={packageNewData.address}
								handleFadeOutModal={handleFadeOutModal}
								setPackages={handlePackages}
								handleModal={handleModal}
								handleFocused={handleFocused}
								focused={focused}
								addressForm={false}
								isSeller={isSeller}
								zIndex={98}
							/>
						</>
					)}
					{modalOpen['courierNewModal'] && (
						<>
							<FlexModal
								setShow={handleModal}
								updateLoading={updateLoading}
								callback={postCourierNew}
								modalName={'courierNewModal'}
								zIndex={99}
								btn1={'Save'}
								btn2={'Cancel'}
								closeBtn={true}
								isForm
								body={
									<CourierNewForm
										handleFormInput={handleCourierNewData}
										handleFormSelectData={handleCourierSelectData}
										formData={courierNewData}
										chosenAddress={courierNewData.address}
										handleAddressInput={handleCourierAddressInput}
										customErrors={customErrors}
									/>
								}
							/>
						</>
					)}
					{modalOpen?.dentallyTokenModal?.open && (
						<>
							<ConfirmModal
								header={modalOpen?.dentallyTokenModal.header}
								callback={() => handleModal('dentallyTokenModal', null)}
								handleModal={handleModal}
								modalType={'dentallyTokenModal'}
								zIndex={999}
								onlyOk
							/>
						</>
					)}
					<MultiStepForm
						setStepOverwrite={setStepOverwrite}
						stepOverwrite={stepOverwrite}
						handleSkipped={handleSkipped}
						formSettings={{
							type: 'order',
							header: { text: 'Ordering', icon: <CartBuyIcon iconClass='formIcon' /> },
							buttons: {
								returnBtn: returnUri ? (
									<Button
										size='md'
										style='secondary'
										minWidth={40}
										maxWidth={40}
										iconLeft={<ArrowLeftIcon />}
										onClick={() => {
											navigate(returnUri);
										}}
									/>
								) : null,
							},
							subHeader: {
								text: `as ${teamState?.currentTeam?.teamInfo?.teamName || 'Yourself'}`,
								icon: <CartBuyIcon iconClass='formIcon' />,
							},
							stepRefs: stepRefs,
							disabled: {
								chooseSupplier: {
									next: !formData?.chosenSupplier ? true : false,
								},
								customForm: {
									prev: changeReqUuid,
								},
								accompaniments: { next: Object.keys(orderItems)?.length < 1 },
								files: { next: Object.keys(orderFiles)?.length < 1 },
								external: { next: Object.keys(orderExtFiles)?.length < 1 },
								submit: !changeReqUuid
									? {
											submit:
												!chosenAddress ||
												formData?.acceptEstimate === undefined ||
												formData?.acceptPricing === undefined
													? true
													: false,
											saveDraft:
												!chosenAddress ||
												formData?.acceptEstimate === undefined ||
												formData?.acceptPricing === undefined
													? true
													: false,
									  }
									: {},
							},
						}}
						formProps={{
							dentallyIntegration: dentallyIntegration,
							handleCustomFormSelectData: handleCustomFormSelectData,
							searchSuppliers: data?.searchSuppliers?.data?.data,
							handleFormSelectData: handleFormSelectData,
							handleCustomFormData: handleCustomFormData,
							handleNestedFormData: handleNestedFormData,
							patients:
								process.env.REACT_APP_ENV === 'development-local'
									? [{ id: '1234', first_name: 'Test', last_name: 'Testson' }]
									: dentallyPatients,
							onBehalfOfAddresses: onBehalfOfAddresses,
							handleAddressInput: handleAddressInput,
							changeReqOrderUuid: changeReqOrderUuid,
							handleDocketSelect: handleDocketSelect,
							handleCustomFiles: handleCustomFiles,
							handlePatientData: handlePatientData,
							handleSearch: updateDebounceSearch,
							addSuppAsTrusted: addSuppAsTrusted,
							setChosenAddress: setChosenAddress,
							setCustomErrors: setCustomErrors,
							customFormFiles: customFormFiles,
							handleFormData: handleFormData,
							selectedDocket: selectedDocket,
							customFormData: customFormData,
							setDtlyLoading: setDtlyLoading,
							packageNewData: packageNewData,
							changeReqUuid: changeReqUuid,
							handleFocused: handleFocused,
							chosenAddress: chosenAddress,
							updateLoading: updateLoading,
							orderExtFiles: orderExtFiles,
							deleteExtFile: deleteExtFile,
							toggleSearch: toggleSearch,
							customErrors: customErrors,
							setSuppliers: setSuppliers,
							dtlyLoading: dtlyLoading,
							getFilePath: getFilePath,
							packages: data?.packages,
							parentOrder: parentOrder,
							handleModal: handleModal,
							setFormOpen: setFormOpen,
							handleNewFiles: handleNewFiles,
							searchOpen: searchOpen,
							hasAddress: hasAddress,
							setAddress: setAddress,
							reloadForm: reloadForm,
							parentUuid: parentUuid,
							removeFile: removeFile,
							orderItems: orderItems,
							deleteItem: deleteItem,
							loadingMessage: (
								<>
									<div className='orderLoading'>
										<h3>Please wait while the order is processing</h3>
										{uploadProgress?.order ? (
											<ProgressBar percent={uploadProgress?.order?.percent} />
										) : (
											<Loading type='circle' />
										)}
									</div>
								</>
							),
							addExtFile: addExtFile,
							deleteFile: deleteFile,
							orderFiles: orderFiles,
							handlePackages: handlePackages,
							suppliers: suppliers,
							chooseSupplier: true,
							modalOpen: modalOpen,
							postForm: postOrder,
							formData: formData,
							formOpen: formOpen,
							addItem: addItem,
							addFile: addFile,
							dockets: dockets,
							loading: loading,
							focused: focused,
							address: address,
							loaded: loaded,
							order: order,
						}}
					/>
				</>
			)}
		</>
	);
}

// || [{ id: '1234', first_name: 'Robin', last_name: 'Neuman' }]
